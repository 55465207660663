import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext'; // Ensure correct path

const Logout = () => {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token
    setIsAuthenticated(false); // Update auth status
    navigate('/login'); // Redirect to login page
  };
  
  const { setIsAuthenticated } = useContext(AuthContext); // Use context to update auth state

  return (
    <div className='logout-button'> <button onClick={handleLogout}>Logout</button></div>
  );
};

export default Logout;
