import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Chip,
  Grid,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/FiberManualRecord';

const People = () => {
  const { id } = useParams();
  const [individual, setIndividual] = useState(null);
  const [filteredIndividuals, setFilteredIndividuals] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const [reportData, setReportData] = useState([]); // Source of truth for reports
  const [displayedReports, setDisplayedReports] = useState([]); // Filtered and modified reports
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [contacts, setContacts] = useState([]); // New state for contacts
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [filter, setFilter] = useState('all');
  const [updatedReports, setUpdatedReports] = useState({});
  const navigate = useNavigate();
  const { selectedBuilding } = useContext(AuthContext);

  const fetchIndividualData = (individualId) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    

    fetch(`/api/individuals/${individualId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => setIndividual(data))
      .catch((error) => console.error('Error fetching individual:', error));

         // Fetch contacts for the selected individual
    fetch(`/api/contacts?individual_id=${individualId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => setContacts(data)) // Set contacts data
      .catch((error) => console.error('Error fetching contacts:', error));


    fetch(`/api/reports?individual_id=${individualId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setReportData(data);
        applyFilterToDisplayedReports(data);
      })
      .catch((error) => console.error('Error fetching report data:', error));
  };
  useEffect(() => {
    applyFilterToDisplayedReports(reportData, filter); // Use current filter state explicitly
  }, [reportData, filter]);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    fetch('/api/individuals', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => setIndividuals(Array.isArray(data) ? data : []))
      .catch((error) => console.error('Error fetching individuals:', error));

    if (id) fetchIndividualData(id);
  }, [id]);

  useEffect(() => {
    if (selectedBuilding) {
      setFilteredIndividuals(
        selectedBuilding.name === 'All'
          ? individuals
          : individuals.filter(
              (individual) => individual.building_location_id === selectedBuilding.id
            )
      );
    }
  }, [selectedBuilding, individuals]);

// Update applyFilterToDisplayedReports to accept a filter parameter
const applyFilterToDisplayedReports = (sourceData, activeFilter) => {
  const filtered = activeFilter === 'collected'
    ? sourceData.filter((report) => report.collected === 1)
    : activeFilter === 'awaiting'
    ? sourceData.filter((report) => report.collected === 0)
    : sourceData;

  const withUnsavedChanges = filtered.map((report) => ({
    ...report,
    ...updatedReports[report.id], // Overwrite with any unsaved changes
  }));

  setDisplayedReports(withUnsavedChanges);
};

const handleFilterChange = (newFilter) => {
  setFilter(newFilter); // Set the filter immediately
  applyFilterToDisplayedReports(reportData, newFilter); // Pass the new filter explicitly
};

  const handleIndividualSelect = (event) => {
    const individualId = event.target.value;
    navigate(`/people/${individualId}`);
  };

  const handleCollectedChange = (reportId, newValue) => {
    setDisplayedReports(prevReports =>
      prevReports.map(report =>
        report.id === reportId ? { ...report, collected: newValue } : report
      )
    );

    setUpdatedReports(prev => ({
      ...prev,
      [reportId]: { ...(prev[reportId] || {}), collected: newValue },
    }));
  };

  const handleDateChange = (date, reportId) => {
    setDisplayedReports((prevReports) =>
      prevReports.map((report) =>
        report.id === reportId ? { ...report, report_date: date.toISOString().slice(0, 10) } : report
      )
    );

    setUpdatedReports((prev) => ({
      ...prev,
      [reportId]: { ...(prev[reportId] || {}), report_date: date.toISOString().slice(0, 10) },
    }));
  };

  const handleUpdateAll = () => {
    const token = localStorage.getItem('token');
    if (!token || Object.keys(updatedReports).length === 0) return;
  
    const updatePromises = Object.entries(updatedReports).map(([reportId, changes]) => {
      const originalReport = reportData.find((r) => r.id === parseInt(reportId));
  
      const dataToSend = {};
      if (changes.hasOwnProperty('collected')) {
        dataToSend.collected = changes.collected;
      } else {
        dataToSend.collected = originalReport.collected;
      }
      if (changes.hasOwnProperty('report_date')) {
        dataToSend.report_date = changes.report_date;
      } else {
        dataToSend.report_date = originalReport.report_date;
      }
  
      return fetch(`/api/reports/${reportId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) return Promise.reject(response);
          return response.json();
        })
        .catch((error) => console.error(`Error updating report ID ${reportId}:`, error));
    });
  
    Promise.all(updatePromises)
      .then(() => {
        fetchIndividualData(id); // Refresh data after updates
        setUpdatedReports({});
        applyFilterToDisplayedReports(reportData, filter); // Apply the current filter instead of 'all'
      })
      .catch((error) => console.error('Error updating data:', error));
  };

  const handleViewNotes = (reportId) => {
    setSelectedNotes(['Sample note for the report']);
    setNotesModalVisible(true);
  };

  const handleAddNote = () => {
    if (newNote.trim()) {
      setSelectedNotes([...selectedNotes, newNote]);
      setNewNote('');
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: { xs: '100%', md: '80%', lg: '60%' }, mx: 'auto' }}>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="individual-select-label">Choose Individual</InputLabel>
        <Select
          labelId="individual-select-label"
          value={id || ''}
          onChange={handleIndividualSelect}
          label="Choose Individual"
        >
          <MenuItem value="" disabled>
            Choose Individual
          </MenuItem>
          {filteredIndividuals.map((individual) => (
            <MenuItem key={individual.id} value={individual.id}>
              {individual.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ToggleButtonGroup
  value={filter}
  exclusive
  onChange={(event, newFilter) => handleFilterChange(newFilter)}
  aria-label="Report Filter"
  sx={{ mb: 3 }}
>
  <ToggleButton value="all" aria-label="All Reports">
    All
  </ToggleButton>
  <ToggleButton value="collected" aria-label="Collected Reports">
    Collected
  </ToggleButton>
  <ToggleButton value="awaiting" aria-label="Awaiting Reports">
    Awaiting
  </ToggleButton>
</ToggleButtonGroup>

      <Box
        sx={{
          p: 3,
          bgcolor: '#F4F6F8',
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          border: '1px solid #d3d3d3',
          mb: 3,
        }}
      >
        <Typography variant="h6" color="primary">{individual?.name || 'Loading...'}</Typography>
        <Typography>Case Manager: {individual?.case_manager}</Typography>
        <Typography>Email: {individual?.case_manager_email}</Typography>
        <Typography>Phone: {individual?.case_manager_phone_number}</Typography>
        <Typography>Building: {individual?.building_location_name}</Typography>

        <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>Contacts</Typography>
          {contacts.length > 0 ? (
            contacts.map((contact) => (
              <Box key={contact.id} sx={{ mb: 2 }}>
                <Typography>Contact Name: {contact.contact_name}</Typography>
                <Typography>Relationship: {contact.relationship || 'N/A'}</Typography>
                <Typography>Email: {contact.email || 'N/A'}</Typography>
                <Typography>Phone: {contact.phone_number || 'N/A'}</Typography>
                <Divider sx={{ my: 1 }} />
              </Box>
            ))
          ) : (
            <Typography>No contacts available for this individual.</Typography>
          )}
      </Box>

      {displayedReports.map((report) => (
        <Box
          key={report.id}
          sx={{
            p: 2,
            bgcolor: '#FFFFFF',
            borderRadius: 2,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: '1px solid #E0E0E0',
            mb: 2,
            position: 'relative',
          }}
        >
          {updatedReports[report.id] && (
            <IconButton size="small" sx={{ position: 'absolute', top: 8, right: 8, color: 'orange' }}>
              <CircleIcon fontSize="small" />
            </IconButton>
          )}

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Chip
                label={report.collected ? 'Form Collected' : 'Awaiting Form'}
                color={report.collected ? 'success' : 'error'}
                size="small"
                sx={{ fontWeight: 'bold' }}
              />
            </Grid>

            <Grid item xs={12} sm={10}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                {report.report_name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={4}>
              <DatePicker
                selected={report.report_date ? new Date(report.report_date) : null}
                onChange={(date) => handleDateChange(date, report.id)}
                dateFormat="yyyy-MM-dd"
                customInput={<TextField size="small" label="Date" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Collected</FormLabel>
                <RadioGroup
                  row
                  value={report.collected} 
                  onChange={(e) => handleCollectedChange(report.id, parseInt(e.target.value, 10))}
                >
                  <FormControlLabel value={1} control={<Radio size="small" />} label="Yes" />
                  <FormControlLabel value={0} control={<Radio size="small" />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button variant="outlined" color="primary" size="small" onClick={() => handleViewNotes(report.id)}>
                  View Notes
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}

      <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={Object.keys(updatedReports).length === 0}
          onClick={handleUpdateAll}
        >
          Update All
        </Button>
      </Box>

      <Dialog open={notesModalVisible} onClose={() => setNotesModalVisible(false)} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6">Form Notes</Typography>
          <Box sx={{ mb: 2 }}>
            {selectedNotes.length > 0 ? (
              selectedNotes.map((note, index) => (
                <Typography key={index}>{note}</Typography>
              ))
            ) : (
              <Typography>No notes available</Typography>
            )}
          </Box>
          <TextField
            label="Add a new note"
            fullWidth
            multiline
            rows={3}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default People;