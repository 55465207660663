import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
  Grid,
  Divider,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const AddUser = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [caseManager, setCaseManager] = useState('');
  const [caseManagerEmail, setCaseManagerEmail] = useState('');
  const [caseManagerPhone, setCaseManagerPhone] = useState('');
  const [buildingLocation, setBuildingLocation] = useState('');
  const [buildingLocations, setBuildingLocations] = useState([]);
  const [forms, setForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [contacts, setContacts] = useState([
    { contact_name: '', relationship: '', email: '', phone_number: '' },
  ]);

  const groupedForms = {
    "IP": ["IP Sign-In","Supports and Services", "Summary of Representation","Supports and Services Assessment"],
    "Level of Need Summary": ["LON", "Level of Need Summary"],
    "CSA (Contract Service Authorization)": ["Contract Service Agreement"],
    "Mobility":["Eating","Transportation","Ambulation"]
  };

  useEffect(() => {
    const fetchReportTypes = async () => {
      try {
        const response = await fetch('/api/report-types', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const data = await response.json();
        setForms(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching report types:', error);
      }
    };

    const fetchBuildingLocations = async () => {
      try {
        const response = await fetch('/api/locations', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const data = await response.json();
        setBuildingLocations(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching building locations:', error);
      }
    };

    fetchReportTypes();
    fetchBuildingLocations();
  }, []);

  const handleCheckboxChange = (formId) => {
    setSelectedForms((prevSelectedForms) =>
      prevSelectedForms.includes(formId)
        ? prevSelectedForms.filter((id) => id !== formId)
        : [...prevSelectedForms, formId]
    );
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    );
    setContacts(newContacts);
  };

  const addContact = () => {
    setContacts([...contacts, { contact_name: '', relationship: '', email: '', phone_number: '' }]);
  };

  const removeContact = (index) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('/api/individuals', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          case_manager: caseManager,
          case_manager_email: caseManagerEmail,
          case_manager_phone_number: caseManagerPhone,
          building_location_id: buildingLocation,
          forms: selectedForms,
          contacts: contacts,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log('User created successfully:', data);
      } else {
        console.error('Error creating user:', data);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const groupedFormNames = Object.values(groupedForms).flat();
  const ungroupedForms = forms.filter((form) => !groupedFormNames.includes(form.report_name));

  return (
    <Box sx={{ p: 2, maxWidth: 1024, margin: 'auto' }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Add New Individual</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: '#2c3e50' }}>Individual Information</Typography>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Case Manager"
              value={caseManager}
              onChange={(e) => setCaseManager(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Case Manager Email"
              value={caseManagerEmail}
              onChange={(e) => setCaseManagerEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Case Manager Phone Number"
              value={caseManagerPhone}
              onChange={(e) => setCaseManagerPhone(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="building-location-label">Building Location</InputLabel>
              <Select
                labelId="building-location-label"
                value={buildingLocation}
                onChange={(e) => setBuildingLocation(e.target.value)}
                label="Building Location"
              >
                {buildingLocations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: '#2c3e50' }}>Required Forms for Individual</Typography>
            <Divider sx={{ mb: 2 }} />

            {Object.entries(groupedForms).map(([groupTitle, reportNames]) => (
              <Box key={groupTitle} sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{groupTitle}</Typography>
                <Grid container spacing={1}>
                  {forms
                    .filter((form) => reportNames.includes(form.report_name))
                    .map((form) => (
                      <Grid item xs={12} sm={6} key={form.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedForms.includes(form.id)}
                              onChange={() => handleCheckboxChange(form.id)}
                            />
                          }
                          label={form.report_name}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>Forms</Typography>
            <Grid container spacing={1}>
              {ungroupedForms.map((form) => (
                <Grid item xs={12} sm={6} key={form.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedForms.includes(form.id)}
                        onChange={() => handleCheckboxChange(form.id)}
                      />
                    }
                    label={form.report_name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: '#2c3e50' }}>Contacts</Typography>
        {contacts.map((contact, index) => (
          <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
            <TextField
              label="Contact Name"
              value={contact.contact_name}
              onChange={(e) => handleContactChange(index, 'contact_name', e.target.value)}
              sx={{ mr: 1 }}
            />
            <TextField
              label="Relationship"
              value={contact.relationship}
              onChange={(e) => handleContactChange(index, 'relationship', e.target.value)}
              sx={{ mr: 1 }}
            />
            <TextField
              label="Email"
              value={contact.email}
              onChange={(e) => handleContactChange(index, 'email', e.target.value)}
              sx={{ mr: 1 }}
            />
            <TextField
              label="Phone Number"
              value={contact.phone_number}
              onChange={(e) => handleContactChange(index, 'phone_number', e.target.value)}
              sx={{ mr: 1 }}
            />
            <IconButton onClick={() => removeContact(index)} color="error">
              <RemoveCircleOutline />
            </IconButton>
          </Box>
        ))}
        <Button startIcon={<AddCircleOutline />} onClick={addContact} sx={{ mb: 2 }}>
          Add Another Contact
        </Button>

        <Divider sx={{ my: 3 }} />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Paper>
    </Box>
  );
};

export default AddUser;