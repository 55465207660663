import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Setup2FA.css';  // Import the CSS file
import Navigation from './Navigation';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api'; // Fallback to localhost during development

const Setup2FA = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [error, setError] = useState(null);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [token, setToken] = useState('');
  const [secret, setSecret] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const check2FAStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/check-2fa`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Check 2FA status response error:', response.status, errorText);
          setError(`Error checking 2FA status: ${response.statusText}`);
          return;
        }

        const data = await response.json();
        if (data.success) {
          setTwoFactorEnabled(data.twoFactorEnabled);
        } else {
          setError('Error checking 2FA status: ' + data.message);
        }
      } catch (error) {
        console.error('Error during fetch:', error); // Log the error
        setError('Failed to check 2FA status: ' + error.message);
      }
    };

    check2FAStatus();
    console.log('Setup2FA component loaded.');
  }, []);

  const setup2FA = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/setup-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Setup 2FA response error:', response.status, errorText);
        setError(`HTTP error! status: ${response.status}`);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setQrCodeUrl(data.qrCodeUrl);
        setSecret(data.secret); // Save the secret for confirmation
        setError(null); // Clear any previous errors
      } else {
        setError('Error setting up 2FA: ' + data.message);
      }
    } catch (error) {
      console.error('Error during fetch:', error); // Log the error
      setError('Failed to set up 2FA: ' + error.message);
    }
  };

  const confirm2FA = async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      setError('User is not authenticated. Please log in.');
      return;
    }

    if (!secret || !token) {
      setError('Please provide the 2FA token.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/confirm-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ token, secret }) // Include the secret in the confirmation request
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Confirm 2FA response error:', response.status, errorText);
        setError(`HTTP error! status: ${response.status}`);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setQrCodeUrl(null); // Clear the QR code after successful confirmation
        setTwoFactorEnabled(true); // Set 2FA status to enabled
        setError(null); // Clear any previous errors
        alert('2FA setup confirmed successfully!');
        navigate('/home'); // Navigate to home page after successful confirmation
      } else {
        setError('Error confirming 2FA: ' + data.message);
      }
    } catch (error) {
      console.error('Error during fetch:', error); // Log the error
      setError('Failed to confirm 2FA: ' + error.message);
    }
  };

  const disable2FA = async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      setError('User is not authenticated. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/disable-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Disable 2FA response error:', response.status, errorText);
        setError(`HTTP error! status: ${response.status}`);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setQrCodeUrl(null);
        setError(null); // Clear any previous errors
        setTwoFactorEnabled(false); // Set 2FA status to disabled
        alert('2FA disabled successfully');
      } else {
        setError('Error disabling 2FA: ' + data.message);
      }
    } catch (error) {
      console.error('Error during fetch:', error); // Log the error
      setError('Failed to disable 2FA: ' + error.message);
    }
  };

  return (
    <>

    <div className="setup-2fa-container">
      <h1>Set Up 2FA</h1>
      {!qrCodeUrl ? (
        <button onClick={setup2FA}>Generate QR Code</button>
      ) : (
        <>
          <img src={qrCodeUrl} alt="2FA QR Code" />
          <label htmlFor="token">2FA Token:</label>
          <input
            type="text"
            id="token"
            name="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
          />
          <button onClick={confirm2FA}>Confirm 2FA Setup</button>
        </>
      )}
      {error && <p className="error">{error}</p>}
      {twoFactorEnabled && (
        <button onClick={disable2FA} style={{ marginTop: '10px' }}>Disable 2FA</button>
      )}
      <div className="links">
        <Link to="/home">Home</Link>
        <Link to="/logout">Logout</Link>
      </div>
    </div>
    </>
  );
};

export default Setup2FA;