import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [selectedBuilding, setSelectedBuilding] = useState(() => {
    const savedBuilding = localStorage.getItem('selectedBuilding');
    return savedBuilding ? JSON.parse(savedBuilding) : null;
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    if (selectedBuilding) {
      localStorage.setItem('selectedBuilding', JSON.stringify(selectedBuilding));
    }
  }, [selectedBuilding]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, selectedBuilding, setSelectedBuilding }}>
      {children}
    </AuthContext.Provider>
  );
};