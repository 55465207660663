import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import {
  AppBar, Toolbar, Typography, Select, MenuItem, Box, Button, FormControl, InputLabel
} from '@mui/material';
import Logo from '../images/seabirdstacks.png'

const Navigation = () => {
  const [buildings, setBuildings] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const { selectedBuilding, setSelectedBuilding } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/locations', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setBuildings(data);
        } else {
          console.error('Failed to fetch buildings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching buildings:', error);
      }
    };

    const fetchIndividuals = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await fetch('/api/individuals', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const data = await response.json();
        setIndividuals(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching individuals:', error);
      }
    };

    fetchBuildings();
    fetchIndividuals();
  }, []);

  const handleBuildingChange = (event) => {
    const buildingId = event.target.value;
    const building = buildings.find((b) => b.id === parseInt(buildingId, 10));
    setSelectedBuilding(building);
  };

  const handleIndividualSelect = (event) => {
    const individualId = event.target.value;
    navigate(`/people/${individualId}`);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Title */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src={Logo} alt="Logo" style={{ width: '85px', height: 'auto' }} />

        </Typography>

        {/* Building Selection */}
        {/* Building Selection */}
<FormControl variant="outlined" sx={{ minWidth: 180, mr: 2 }}>
  <InputLabel id="building-select-label" sx={{ color: 'white' }}>
    Select a building
  </InputLabel>
  <Select
    labelId="building-select-label"
    value={selectedBuilding?.id || ''}
    onChange={handleBuildingChange}
    label="Select a building"
    sx={{
      color: 'white', // Set text color to white
      '.MuiSvgIcon-root': { color: 'white' }, // Change the dropdown arrow to white
    }}
  >
    <MenuItem value="" disabled>
      <span style={{ color: 'gray' }}>Select a building</span>
    </MenuItem>
    {buildings.map((building) => (
      <MenuItem key={building.id} value={building.id} sx={{ color: 'black' }}>
        {building.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

        {/* Navigation Links */}
        <Box component="nav" sx={{ height: '80px',  // Increase height
    paddingY: 2,     // Vertical padding
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#70093CB',
    color: 'white',
 }}>
          <Button component={Link} to="/home" color="inherit">
            Home
          </Button>
          <Button component={Link} to="/setup-2fa" color="inherit">
            Setup 2FA
          </Button>
          <Button component={Link} to="/add-user" color="inherit">
            Add user
          </Button>
          <Button component={Link} to="/dashboard" color="inherit">
            Dashboard
          </Button>
          <Button component={Link} to="/logout" color="inherit">
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;