import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, Typography, TableSortLabel
} from '@mui/material';

const Dashboard = () => {
  const [locations, setLocations] = useState([]);
  const [filteredIndividuals, setFilteredIndividuals] = useState([]);
  const { selectedBuilding } = useContext(AuthContext);
  const [individuals, setIndividuals] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filterReport, setFilterReport] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }
    
    const fetchData = async () => {
      try {
        const locationsResponse = await fetch('/api/locations', { headers: { 'Authorization': `Bearer ${token}` } });
        const locationsData = await locationsResponse.json();
        setLocations(Array.isArray(locationsData) ? locationsData : []);

        const individualsResponse = await fetch('/api/individuals', { headers: { 'Authorization': `Bearer ${token}` } });
        const individualsData = await individualsResponse.json();
        setIndividuals(Array.isArray(individualsData) ? individualsData : []);

        const reportDataResponse = await fetch('/api/reports/upcoming', { headers: { 'Authorization': `Bearer ${token}` } });
        const reportData = await reportDataResponse.json();
        setReportData(Array.isArray(reportData) ? reportData : []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []);

  const sortedReports = React.useMemo(() => {
    let sortableReports = [...reportData];
    if (sortConfig !== null) {
      sortableReports.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableReports;
  }, [reportData, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') direction = 'descending';
    setSortConfig({ key, direction });
  };

  const filteredReports = sortedReports.filter(report =>
    report.individual_name.toLowerCase().includes(filterName.toLowerCase()) &&
    report.report_name.toLowerCase().includes(filterReport.toLowerCase())
  );

  const calculateDaysBetween = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1 - date2) / oneDay));
  };

  const getBackgroundColor = (daysSinceReport) => {
    if (daysSinceReport >= 90) return '#F55F5F'; // Red for expired (90+ days)
    if (daysSinceReport >= 60) return '#E0E000'; // Yellow for 60-89 days
    if (daysSinceReport >= 45) return 'lightblue'; // Blue for 45-59 days
    return 'transparent';
  };

  return (
    <Box>
      <Box sx={{ p: 3, maxWidth: '1000px', mx: 'auto' }}>
        <Typography variant="h6" gutterBottom>
          Dashboard
        </Typography>
        
        {/* Legend */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <div className="legend-item">
            <Box sx={{ width: 20, height: 20, backgroundColor: 'lightblue', display: 'inline-block', mr: 1 }} />
            <span>45+ days since submission</span>
          </div>
          <div className="legend-item">
            <Box sx={{ width: 20, height: 20, backgroundColor: '#E0E000', display: 'inline-block', mr: 1 }} />
            <span>30 days before form expiration</span>
          </div>
          <div className="legend-item">
            <Box sx={{ width: 20, height: 20, backgroundColor: '#F55F5F', display: 'inline-block', mr: 1 }} />
            <span>90+ days since form submission</span>
          </div>
        </Box>

        {/* Filters */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Filter by name"
            variant="outlined"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Filter by report"
            variant="outlined"
            value={filterReport}
            onChange={(e) => setFilterReport(e.target.value)}
            fullWidth
          />
        </Box>

        {/* Report Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig?.key === 'individual_name'}
                    direction={sortConfig?.key === 'individual_name' ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort('individual_name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig?.key === 'report_name'}
                    direction={sortConfig?.key === 'report_name' ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort('report_name')}
                  >
                    Report
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig?.key === 'building_location_name'}
                    direction={sortConfig?.key === 'building_location_name' ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort('building_location_name')}
                  >
                    Location
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig?.key === 'report_date'}
                    direction={sortConfig?.key === 'report_date' ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort('report_date')}
                  >
                    Report Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig?.key === 'days_since_report'}
                    direction={sortConfig?.key === 'days_since_report' ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort('days_since_report')}
                  >
                    Days Ago
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredReports.map((report) => {
                const daysSinceReport = report.days_since_report;
                const backgroundColor = getBackgroundColor(daysSinceReport);
                return (
                  <TableRow
                    key={report.id}
                    component={Link}
                    to={`/people/${report.individual_id}`}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor,
                          borderRadius: '50%',
                          mx: 'auto',
                        }}
                      />
                    </TableCell>
                    <TableCell>{report.individual_name}</TableCell>
                    <TableCell>{report.report_name}</TableCell>
                    <TableCell>{report.building_location_name}</TableCell>
                    <TableCell>{new Date(report.report_date).toLocaleDateString()}</TableCell>
                    <TableCell>{daysSinceReport} days ago</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Dashboard;