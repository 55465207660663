import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import seabirdStacksPhoto from './../images/seabirdstacks.png';
import { AuthContext } from '../components/AuthContext';
import './Login.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      alert('Please provide both username and password.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      if (data.twoFactorRequired) {
        setTwoFactorRequired(true);
        setUserId(data.userId);
      } else if (data.token) {
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        alert('Login failed: ' + data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Error processing your request. Please try again later.');
    }
  };

  const handle2FASubmit = async (event) => {
    event.preventDefault();
    if (!token) return alert('Please provide the 2FA token.');

    try {
      const response = await fetch(`${API_URL}/verify-2fa`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, token }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
        navigate('/home');
      } else {
        alert('2FA verification failed: ' + data.message);
      }
    } catch (error) {
      console.error('2FA error:', error);
      alert('Error processing your request. Please try again later.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-content-container">
    
        <form onSubmit={twoFactorRequired ? handle2FASubmit : handleLoginSubmit} className="login__form">
          <h2>{twoFactorRequired ? '2FA Verification' : 'Login to Forms'}</h2>
          {!twoFactorRequired ? (
            <>
              <label htmlFor="username" className="login__label">Username</label>
              <input
                type="text"
                id="username"
                className="login__input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="Username"
              />
              <label htmlFor="password" className="login__label">Password</label>
              <input
                type="password"
                id="password"
                className="login__input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
              />
              <button type="submit" className="login__button">Login</button>
            </>
          ) : (
            <>
              <label htmlFor="token" className="login__label">2FA Token</label>
              <input
                type="text"
                id="token"
                className="login__input"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
                placeholder="Enter 2FA token"
              />
              <button type="submit" className="login__button">Verify 2FA</button>
            </>
          )}
        </form>
      </div>
      <div className="login-image-container">
        <img src={seabirdStacksPhoto} alt="Seabird Stacks" className="login-image" />
      </div>
    </div>
  );
};

export default Login;